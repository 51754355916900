<template>
  <div id="app">
    <router-view /> <!-- اینجا برای نمایش صفحه لاگین یا داشبورد بر اساس روت -->
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
body, html, #app {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body::-webkit-scrollbar, html::-webkit-scrollbar {
  display: none;
}
</style>

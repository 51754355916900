<template>
  <div class="login-container">
    <div class="login-card">
      <!-- محل SVG -->
      <div class="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="227.416" height="180.04" viewBox="0 0 227.416 180.04">
          <g id="Group_33" data-name="Group 33" transform="translate(-15.207 -85.603)">
            <path id="Path_324" data-name="Path 324"
                  d="M76.931,264.927H200c23.332,0,36.847-26.39,23.189-45.276a28.524,28.524,0,0,1,3.066-37.013l4.429-4.378a35.8,35.8,0,0,0-12.748-59.047l-86.792-32.2a22.379,22.379,0,0,0-30.057,18.665A22.369,22.369,0,0,1,77.411,125.7l-5.913-.374a28.521,28.521,0,0,0-30.346,28.438v11.577a39.634,39.634,0,0,1-8.94,24.961c-14.229,17.424-11.774,43.329,5.73,57.471l7.426,6A50.226,50.226,0,0,0,76.931,264.927Z"
                  transform="translate(-4.112)" fill="rgba(18,117,105,0.31)"/>
            <path id="Path_325" data-name="Path 325"
                  d="M376.965,409.638c-7.375-.486-13.93,17.816-15.979,26.724H365.9C372.458,427.859,384.34,410.124,376.965,409.638Z"
                  transform="translate(-182.945 -171.435)" fill="#032068"/>
            <path id="Path_326" data-name="Path 326"
                  d="M368.908,352.222c-12.537-1.215-18.334,34.923-19.666,53.144h8.911C366.962,388.158,381.445,353.437,368.908,352.222Z"
                  transform="translate(-176.731 -141.047)" fill="#fff"/>
            <path id="Path_327" data-name="Path 327"
                  d="M349.175,404.3h8.049c4.38-8.59,10.1-21.381,13.205-32.2,1.574-5.479,2.463-10.4,2.2-14a8.25,8.25,0,0,0-1.256-4.215,4.118,4.118,0,0,0-3.132-1.716c-2.843-.276-5.4,1.551-7.7,5-2.285,3.438-4.21,8.352-5.8,13.915C351.64,381.974,349.869,395.215,349.175,404.3Zm10.517-47.687c2.359-3.55,5.226-5.786,8.65-5.454a5.136,5.136,0,0,1,3.865,2.137,9.22,9.22,0,0,1,1.441,4.725c.274,3.786-.657,8.848-2.238,14.352-3.167,11.027-9.006,24.041-13.417,32.659l-.142.276h-9.773l.04-.54c.667-9.129,2.453-22.756,5.649-33.96C355.363,365.206,357.321,360.175,359.691,356.609Z"
                  transform="translate(-176.115 -140.481)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_328" data-name="Path 328"
                  d="M372.182,368.361l-5.83,20.1,5.116-2.469.447.906-5.942,2.868-7,24.149-.98-.278,3.491-12.033-2.256-6.8.969-.314,1.788,5.39,5.912-20.384-2.815-6.516.938-.4,2.323,5.377,2.864-9.873Z"
                  transform="translate(-181.358 -149.454)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_329" data-name="Path 329"
                  d="M292.008,140.993l-.122-3.538a.648.648,0,0,1,1.162-.418l2.109,2.74a.648.648,0,0,0,.536.252l3.781-.131a.649.649,0,0,1,.451,1.135l-2.874,2.524a.648.648,0,0,0-.221.486l-.012,4.2a.649.649,0,0,1-1.169.385l-2.347-3.164a.648.648,0,0,0-.483-.261l-4.278-.252a.648.648,0,0,1-.307-1.2l3.473-2.192A.649.649,0,0,0,292.008,140.993Z"
                  transform="translate(-144.292 -27.078)" fill="#fff"/>
            <path id="Path_330" data-name="Path 330"
                  d="M378.859,180.962l.455-1.049a.648.648,0,0,1,1.229.121l.234,1.085a.649.649,0,0,0,.376.459l1.272.552a.648.648,0,0,1-.081,1.219l-1.373.39a.647.647,0,0,0-.408.343l-.69,1.44a.648.648,0,0,1-1.221-.155l-.277-1.409a.65.65,0,0,0-.324-.443l-1.416-.779a.649.649,0,0,1,.236-1.212l1.471-.176A.648.648,0,0,0,378.859,180.962Z"
                  transform="translate(-191.046 -49.691)" fill="#fff"/>
            <path id="Path_331" data-name="Path 331"
                  d="M210.735,176.268a34.637,34.637,0,0,0-34.63,34.643v26.665a2.149,2.149,0,0,0,2.149,2.15h6.058a2.149,2.149,0,0,0,2.149-2.15V210.263a24.273,24.273,0,1,1,48.546,0V217.5a2.149,2.149,0,0,0,2.149,2.15h6.058a2.149,2.149,0,0,0,2.149-2.15v-6.591A34.637,34.637,0,0,0,210.735,176.268Z"
                  transform="translate(-85.128 -47.969)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_332" data-name="Path 332"
                  d="M148.615,296.67a2.149,2.149,0,0,1,2.149-2.15h90.854a2.149,2.149,0,0,1,2.149,2.15v76.641a2.149,2.149,0,0,1-2.149,2.15H150.764a2.149,2.149,0,0,1-2.149-2.15Z"
                  transform="translate(-70.583 -110.534)" fill="#fff"/>
            <path id="Path_333" data-name="Path 333"
                  d="M147.094,295.864A2.866,2.866,0,0,1,149.959,293h90.853a2.866,2.866,0,0,1,2.866,2.867v76.641a2.866,2.866,0,0,1-2.866,2.866H149.959a2.866,2.866,0,0,1-2.865-2.866Zm2.865-1.433a1.433,1.433,0,0,0-1.433,1.433v76.641a1.433,1.433,0,0,0,1.433,1.433h90.853a1.433,1.433,0,0,0,1.433-1.433V295.864a1.433,1.433,0,0,0-1.433-1.433Z"
                  transform="translate(-69.779 -109.728)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_334" data-name="Path 334"
                  d="M245.3,367.825a8.738,8.738,0,1,0-10.4.408l-1.95,8.192a1.943,1.943,0,0,0,1.889,2.393h10.618a1.943,1.943,0,0,0,1.889-2.393Z"
                  transform="translate(-114.217 -141.088)" fill="#f4ad5e" fill-rule="evenodd"/>
            <path id="Path_335" data-name="Path 335"
                  d="M141.906,149.372A17.6,17.6,0,0,1,115.144,164.4l-5.239,1.858a1.183,1.183,0,0,1-1.55-1.369l1.279-5.8a17.6,17.6,0,1,1,32.272-9.721Z"
                  transform="translate(-48.411 -24.427)" fill="#fff"/>
            <path id="Path_336" data-name="Path 336"
                  d="M123.864,131.724a17.2,17.2,0,1,1-8.957,31.9l-.161-.1-5.415,1.92a.789.789,0,0,1-1.034-.913l1.315-5.959-.093-.139a17.208,17.208,0,0,1,14.345-26.708Zm17.993,17.205a17.993,17.993,0,1,0-33.087,9.8l-1.243,5.634a1.577,1.577,0,0,0,2.067,1.826l5.062-1.8a18,18,0,0,0,27.2-15.462Z"
                  transform="translate(-47.968 -23.985)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_337" data-name="Path 337"
                  d="M133.167,168.152a9.671,9.671,0,0,1,9.547,8.58,1.082,1.082,0,0,1-1.113,1.181H124.733a1.082,1.082,0,0,1-1.113-1.181A9.671,9.671,0,0,1,133.167,168.152Z"
                  transform="translate(-57.355 -43.675)" fill="#f4ad5e"/>
            <path id="Path_338" data-name="Path 338"
                  d="M134.276,150.859a4.593,4.593,0,1,0,4.593-4.593A4.593,4.593,0,0,0,134.276,150.859Z"
                  transform="translate(-62.997 -32.095)" fill="#f4ad5e"/>
            <rect id="Rectangle_15" data-name="Rectangle 15" width="73.929" height="85.112" rx="3"
                  transform="translate(167.693 158.187)" fill="#fff" stroke="#032068" stroke-width="2"/>
            <rect id="Rectangle_16" data-name="Rectangle 16" width="49.7" height="11.183" rx="3"
                  transform="translate(180.118 179.309)" fill="none" stroke="#e7e8ff" stroke-width="2"/>
            <rect id="Rectangle_17" data-name="Rectangle 17" width="49.7" height="11.183" rx="3"
                  transform="translate(180.118 202.296)" fill="none" stroke="#e7e8ff" stroke-width="2"/>
            <rect id="Rectangle_18" data-name="Rectangle 18" width="26.093" height="9.94" rx="3"
                  transform="translate(191.924 222.176)" fill="#032068"/>
            <rect id="Rectangle_19" data-name="Rectangle 19" width="18.638" height="2.485" rx="1.243"
                  transform="translate(195.65 168.748)" fill="#032068"/>
            <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(184.468 206.644)" fill="#f4ad5e"/>
            <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(191.924 206.644)" fill="#f4ad5e"/>
            <circle id="Ellipse_5" data-name="Ellipse 5" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(199.376 206.644)" fill="#f4ad5e"/>
            <circle id="Ellipse_6" data-name="Ellipse 6" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(206.831 206.644)" fill="#f4ad5e"/>
            <circle id="Ellipse_7" data-name="Ellipse 7" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(214.287 206.644)" fill="#f4ad5e"/>
            <circle id="Ellipse_8" data-name="Ellipse 8" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(184.464 183.658)" fill="#f4ad5e"/>
            <circle id="Ellipse_9" data-name="Ellipse 9" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(191.92 183.658)" fill="#f4ad5e"/>
            <circle id="Ellipse_10" data-name="Ellipse 10" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(199.375 183.658)" fill="#f4ad5e"/>
            <circle id="Ellipse_11" data-name="Ellipse 11" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(206.83 183.658)" fill="#f4ad5e"/>
            <circle id="Ellipse_12" data-name="Ellipse 12" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(214.285 183.658)" fill="#f4ad5e"/>
            <circle id="Ellipse_13" data-name="Ellipse 13" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(221.74 183.658)" fill="#f4ad5e"/>
            <circle id="Ellipse_14" data-name="Ellipse 14" cx="1.553" cy="1.553" r="1.553"
                    transform="translate(221.741 206.644)" fill="#f4ad5e"/>
            <path id="Path_339" data-name="Path 339"
                  d="M84.336,258.376,83.048,266.1a7.33,7.33,0,0,0,7.415,5.152c4.9,0,6.556-3,6.771-4.508l-1.612-6.118c4.514-.966,4.192-10.626,2.579-15.134-1.611-5.474-9.994-7.084-15.153-.644A10.545,10.545,0,0,0,84.336,258.376Z"
                  transform="translate(-34.891 -82.041)" fill="#efcb96"/>
            <path id="Path_340" data-name="Path 340"
                  d="M80.456,250.1l-1.34,4.281a.463.463,0,0,1-.817.123c-10.139-14.127-5.843-19.555-2.357-20.5,1.29-2.576,5.159-4.83,11.607-5.8s9.35,3.542,8.705,8.372c-.516,3.864-7.523,3.971-10.963,3.542,0,1.288-1.934,8.05-2.579,7.406s-2.257-4.508-3.547-2.254C78.135,247.078,79.6,249.246,80.456,250.1Z"
                  transform="translate(-30.041 -75.38)" fill="#032068"/>
            <path id="Path_341" data-name="Path 341"
                  d="M100.924,283.059a10.97,10.97,0,0,0,5.158,0l.645,2.254A6.757,6.757,0,0,1,100.924,283.059Z"
                  transform="translate(-45.351 -104.47)" fill="#032068"/>
            <path id="Path_342" data-name="Path 342"
                  d="M55.573,297.179c4.9,0,6.771-2.576,7.416-3.864,3.547,0,18.056,3.22,23.537,14.168s7.093,35.42,7.738,39.284c.516,3.092,6.878,19.964,10,28.015H17.2c-4.385,0-.537-24.043,1.936-36.065-1.182-4.937-2.644-17.452.967-28.014S40.1,294.71,47.835,293.315C48.157,294.6,50.672,297.179,55.573,297.179Z"
                  transform="translate(0 -109.896)" fill="#f4ad5e"/>
            <path id="Path_343" data-name="Path 343"
                  d="M72.173,383.461l-2.579-12.88L75.4,382.5c10-.966,18.593-1.717,21.28-1.61-2.321,0-20.957,4.293-29.985,6.44Z"
                  transform="translate(-27.239 -150.776)" fill="#032068"/>
            <path id="Path_344" data-name="Path 344"
                  d="M146.24,312.549a.462.462,0,0,1,.468-.457l4.064.045a.462.462,0,0,1,.458.467l-.021,1.923a.462.462,0,0,0,.458.468l6.2.069a.462.462,0,0,1,.458.467l-.037,3.347a.464.464,0,0,1-.469.457l-6.2-.069a.463.463,0,0,0-.468.457l-.022,1.923a.463.463,0,0,0,.458.468l3.352.037a.463.463,0,0,1,.458.468l-.038,3.347a.463.463,0,0,1-.468.457l-3.352-.037a.462.462,0,0,0-.468.457L150.43,384.3a.463.463,0,0,1-.468.457l-4.064-.045a.462.462,0,0,1-.458-.468Z"
                  transform="translate(-68.904 -119.831)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_345" data-name="Path 345"
                  d="M22.469,389.727c1.29,10.626,8.383,25.31,16.121,28.658,10.283,3.927,30.778-7.729,37.005-12.644q.545-.455,1.041-.88c-.28.26-.629.555-1.041.88-7.912,6.605-23.427,17.669-36.683,14.9-10.64-2.576-16.659-17.925-17.411-26.082Z"
                  transform="translate(-3.331 -160.906)" fill="#032068"/>
            <path id="Path_346" data-name="Path 346"
                  d="M58.73,444.955a24.228,24.228,0,0,0-18.056-6.118,19,19,0,0,0,7.738,6.118C52.281,446.5,56.9,445.6,58.73,444.955Z"
                  transform="translate(-13.474 -186.832)" fill="#032068"/>
            <path id="Path_347" data-name="Path 347"
                  d="M135.519,383.824c0,.976-1.61,2.44-2.415,3.051l1.509,7.931a27.814,27.814,0,0,1,3.924-1.525c2.113-.61,6.339-.61,8.15-2.44s1.509-9.152,0-10.677c-1.207-1.221-5.333.915-7.245,2.135v-4.881C136.424,378.639,135.519,382.6,135.519,383.824Z"
                  transform="translate(-62.377 -154.393)" fill="#efcb96"/>
            <path id="Path_348" data-name="Path 348" d="M163.229,345.966c.774,8.243,2.472,41.216,3.224,56.672h2.257Z"
                  transform="translate(-78.315 -137.753)" fill="#032068"/>
          </g>
        </svg>
      </div>
      <h1 class="welcome">Welcome!</h1>
      <p class="sub-title">Sign in to access your dashboard</p>
      <form @submit.prevent="validateAndLogin">
        <div class="form-group">
          <input
              v-model="username"
              placeholder="Username"
              required
              :class="{ error: errors.username }"
          />
          <span v-if="errors.username" class="error-message">{{ errors.username }}</span>
        </div>
        <div class="form-group">
          <input
              v-model="password"
              type="password"
              placeholder="Password"
              required
              :class="{ error: errors.password }"
          />
          <span v-if="errors.password" class="error-message">{{ errors.password }}</span>
        </div>
        <div class="remember-me">
          <label class="remember-row" for="rememberMe">
            <input id="rememberMe" class="checkbox" type="checkbox" v-model="rememberMe"/>
            <span class="remember-text">Remember me</span>
          </label>
        </div>

        <button type="submit" :disabled="isLoading" class="submit-btn">
          <span v-if="isLoading" class="spinner"></span>
          <span v-else>Sign in</span>
        </button>
      </form>
    </div>

    <!-- نمایش توست -->
    <Toast
        v-if="toastMessage"
        :message="toastMessage"
        :type="toastType"
        :duration="3000"
    />
  </div>
</template>

<script>
import Toast from './Toast.vue'; // وارد کردن کامپوننت Toast
import axios from 'axios';

export default {
  name: "LoginComponent",
  components: {
    Toast,
  },
  data() {
    return {
      username: "",
      password: "",
      rememberMe: false,
      isLoading: false,
      toastMessage: null, // پیغام توست
      toastType: null, // نوع توست (موفق یا خطا)
      errors: {
        username: null,
        password: null,
      },
    };
  },
  mounted() {
    // اگر کاربر لاگین شده باشد و توکن داشته باشد، او را به داشبورد هدایت می‌کنیم
    if (localStorage.getItem("token")) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    validateAndLogin() {
      this.errors = {username: null, password: null};

      // اعتبارسنجی فرم
      if (!this.username) {
        this.errors.username = "Username is required!";
        return;
      }
      if (!this.password) {
        this.errors.password = "Password is required!";
        return;
      }

      // شروع فرآیند لاگین
      this.isLoading = true;

      axios.post(process.env.VUE_APP_BASE_API_URL + '/api/tenant/login', {
        username: this.username,
        password: this.password,
        tenant: this.username,
      }, {
        headers: {
        }
      })
      .then(response => {
        var data = response.data;
        console.log(data)
        localStorage.setItem("token", data.token);
        this.showToast("Login successful! Redirecting...", "success");
        setTimeout(() => {
          this.$router.push("/dashboard");
        }, 300);
      })
      .catch(error => {
        console.error("There was an error!", error);
        this.showToast("Invalid username or password.", "error");
      })
      .finally(() => {
        this.isLoading = false;
      })

    },
    showToast(message, type) {
      this.toastMessage = message;
      this.toastType = type;
    },
  },
};
</script>

<style scoped>

* {
  font-family: 'Nunito', sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background-color: #f8f9fa;
  padding: 15px;

}

.login-card {
  min-height: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.welcome {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0 auto -17px;
}

.sub-title {
  color: #6c757d;
  margin-bottom: 15px;
}

.svg-container {
  margin: 5px auto  5px;
}

.form-group {
  margin-bottom: 10px;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 0;
  font-family: 'Nunito', sans-serif;
}

input.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  text-align: left;
}

.remember-me {
  margin-top: -5px;
  width: 80px;
  text-align: left;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.remember-row {
  margin-top: 3px;
  width: 80px;
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox {
  margin-top: 0;
  min-width: 30px;
}

.remember-text {
  min-width: 220px;
}

.submit-btn {
  height: 40px;
  width: 100%;
  padding: 5px;
  background-color: #127569;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:disabled {
  background-color: #ccc;
}

.submit-btn:hover:not(:disabled) {
  background-color: #127569;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

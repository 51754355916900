import { createRouter, createWebHistory } from "vue-router";
import Login from "../components/Login.vue";
import Dashboard from "../components/Dashboard.vue";
import store from "../store"; // اضافه کردن store برای بررسی توکن

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      // اگر کاربر لاگین کرده و توکن دارد، به داشبورد هدایت شود
      if (store.state.token) {
        next("/dashboard");
      } else {
        next();
      }

      next();
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true }, // محافظت‌شده
  },
  {
    path: "/:catchAll(.*)", // برای هدایت تمامی مسیرهای نادرست به لاگین
    redirect: "/login",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// بررسی احراز هویت قبل از هر مسیریابی
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token"); // Or Vuex store state
  if (to.meta.requiresAuth && !token) {
    next("/login");
  } else if (to.path === "/login" && token) {
    next("/dashboard"); // If already logged in, go to dashboard
  } else {
    next(); // Proceed as normal
  }
});

export default router;

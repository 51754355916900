<template>
  <div class="dashboard-page">
    <div class="top-nav">
      <nav class="navbar">
        <div class="navbar-left">
          <h1>VPN Dashboard</h1>
        </div>
        <div class="navbar-right">
          <button @click="logout" class="logout-btn">Logout</button>
        </div>
      </nav>
    </div>
    <div class="dashboard-container">
      <div class="row-container1">
        <div class="upper-row">
          <div class="row-header">
            <div class="row-header-icon"
                 style="width: 16px; height: 25px; background-color: #127569; border-radius: 5px;"></div>
            <div class="row-header-title">Overview</div>
          </div>
          <div class="header-line"></div>
        </div>

        <div class="stats-container">
          <div class="stat-box">
            <div class="stat-header">
              <div class="stat-icon" style="background-color: #1F8FFF;"></div>
              <div class="stat-title">Total Users</div>
            </div>
            <div class="stat-value">{{ totalUsers }}</div>
          </div>

          <div class="stat-box">
            <div class="stat-header">
              <div class="stat-icon" style="background-color: #4caf50;"></div>
              <div class="stat-title">Active Users</div>
            </div>
            <div class="stat-value">{{ activeUsers }}</div>
          </div>

          <div class="stat-box">
            <div class="stat-header">
              <div class="stat-icon" style="background-color: #f44336;"></div>
              <div class="stat-title">Expired Users</div>
            </div>
            <div class="stat-value">{{ expiredUsers }}</div>
          </div>

          <div class="stat-box">
            <div class="stat-header">
              <div class="stat-icon" style="background-color: #535353;"></div>
              <div class="stat-title">To hold Users</div>
            </div>
            <div class="stat-value">{{ blockedUsers }}</div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <button @click="showCreateUserModal = true" class="open-modal-button">Create User</button>
        <button @click="showBatchActionModal = true" class="open-modal-button">Batch Action</button>
      </div>
      <div class="row-container2">
        <div class="upper-row">
          <div class="row-header">
            <div class="row-header-icon"
                 style="width: 16px; height: 25px; background-color: #127569; border-radius: 5px;"></div>
            <div class="header-svg"
                 style="display: flex; align-items: center; justify-content: space-between; width: 100%">
              <div class="row-header-title">Users</div>
              <div style="margin-top: -5px; margin-right: 5px" class="user-per-page">
                <span style="font-weight: bold; font-size: 14px; margin-right: 5px;">Items:</span>
                <select id="rowsPerPage" class="rows-per-page-select" v-model="rowsPerPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="All">All</option>
                </select>
              </div>
            </div>
          </div>
          <div class="header-line"></div>
        </div>
        <div class="search-container">
          <input
              type="text"
              placeholder="Search"
              class="search-input"
              v-model="searchQuery"
          />
          <span class="search-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="19.091" height="19" viewBox="0 0 19.091 19">
      <path id="Combined_Shape" data-name="Combined Shape"
            d="M16.293,17.708,12.81,14.389a8.011,8.011,0,1,1,1.435-1.394l3.462,3.3a1,1,0,0,1-1.415,1.415ZM2,8A6,6,0,1,0,8,2,6.007,6.007,0,0,0,2,8Z"
            transform="translate(0.5 0.5)" fill="#707070" stroke="#707070" stroke-width="1"/>
    </svg>
  </span>
        </div>
        <div v-if="totalPages > 1" class="pagination-top">
          <button class="bp-button" @click="previousPage" :disabled="currentPage === 1">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
              <path d="M15 19l-7-7 7-7" fill="#127569"/>
            </svg>
          </button>
          <!-- نمایش صفحات بدون نقاط -->
          <button
              v-for="(page, index) in filteredVisiblePages"
              :key="index"
              @click="goToPage(page)"
              :class="{ 'active-page': page === currentPage }"
              class="nm-button">
            {{ page }}
          </button>
          <!-- نمایش نقاط -->
          <span
              v-for="(page, index) in dotPages"
              :key="index"
              class="dots">...</span>
          <button class="bp-button" @click="nextPage" :disabled="currentPage === totalPages">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
              <path d="M9 5l7 7-7 7" fill="#127569" />
            </svg>
          </button>
        </div>
        <div class="table-container">
          <table class="user-table">
            <thead>
            <tr>
              <th class="column-user-pass">Username</th>
              <th class="column-expiry">Expiry</th>
              <th class="column-status">Status</th>
              <th class="column-action">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="user in paginatedUsers" :key="user.id" :class="getUserRowClass(user.status)">
              <td>
                <div>{{ user.username }}</div>
                <div>@{{ user.telegramId }}</div>
              </td>
              <td>
                <div
                    :class="{'text-green': calculateDaysLeft(user.expiryDate).startsWith('+'), 'text-red': calculateDaysLeft(user.expiryDate).startsWith('-')}">
                  {{ calculateDaysLeft(user.expiryDate) }}
                </div>
                <div>{{ user.activeDevicesCount }}/{{ user.maxDevicesCount }}</div>
              </td>
              <td>
                <div>{{ getUserStatusText(user.status) }}</div>
              </td>
              <td>
                <button @click="openEditModal(user)" class="action-button">Edit</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-if="totalPages > 1" class="pagination-button">
          <button class="bp-button" @click="previousPage" :disabled="currentPage === 1">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
              <path d="M15 19l-7-7 7-7" fill="#127569"/>
            </svg>
          </button>
          <!-- نمایش صفحات بدون نقاط -->
          <button
              v-for="(page, index) in filteredVisiblePages"
              :key="index"
              @click="goToPage(page)"
              :class="{ 'active-page': page === currentPage }"
              class="nm-button">
            {{ page }}
          </button>
          <span
              v-for="(page, index) in dotPages"
              :key="index"
              class="dots">...</span>
          <button class="bp-button" @click="nextPage" :disabled="currentPage === totalPages">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
              <path d="M9 5l7 7-7 7" fill="#127569" />
            </svg>
          </button>
        </div>

      </div>
    </div>
  </div>
  <ModalComponent v-if="showCreateUserModal" :isVisible="showCreateUserModal" title="Create User"
                  @close="showCreateUserModal = false">
    <div class="row-container2">
      <div class="upper-row">
        <div class="row-header">
          <div class="row-header-icon"
               style="width: 16px; height: 25px; background-color: #127569; border-radius: 5px;"></div>
          <div class="header-svg"
               style="display: flex; align-items: center; justify-content: space-between; width: 100%">
            <div class="row-header-title">Create User</div>
            <svg @click="closeModal" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 style="margin-top: -3px; margin-right: 2px">
              <path id="Combined_Shape" data-name="Combined Shape"
                    d="M9.809,24c-1.458,0-2.611,0-3.554-.077a6.771,6.771,0,0,1-2.616-.65A6.616,6.616,0,0,1,.726,20.36a6.766,6.766,0,0,1-.65-2.616C0,16.8,0,15.648,0,14.189V9.809C0,8.351,0,7.2.076,6.255a6.771,6.771,0,0,1,.65-2.616A6.622,6.622,0,0,1,3.639.726,6.771,6.771,0,0,1,6.255.076C7.2,0,8.351,0,9.809,0h4.38c1.459,0,2.611,0,3.554.077a6.766,6.766,0,0,1,2.616.65,6.616,6.616,0,0,1,2.912,2.912,6.771,6.771,0,0,1,.65,2.616C24,7.2,24,8.351,24,9.809v4.38c0,1.459,0,2.611-.077,3.554a6.766,6.766,0,0,1-.65,2.616,6.61,6.61,0,0,1-2.912,2.912,6.766,6.766,0,0,1-2.616.65C16.8,24,15.648,24,14.189,24ZM6.472,2.734A4.285,4.285,0,0,0,4.85,3.1,3.973,3.973,0,0,0,3.1,4.85a4.285,4.285,0,0,0-.368,1.622c-.068.838-.068,1.909-.068,3.394v4.267c0,1.484,0,2.557.068,3.394A4.279,4.279,0,0,0,3.1,19.149,3.967,3.967,0,0,0,4.85,20.9a4.262,4.262,0,0,0,1.622.368c.838.068,1.909.068,3.394.068h4.267c1.484,0,2.557,0,3.394-.068a4.256,4.256,0,0,0,1.622-.368A3.955,3.955,0,0,0,20.9,19.149a4.256,4.256,0,0,0,.368-1.622c.068-.836.068-1.909.068-3.394V9.866c0-1.484,0-2.556-.068-3.394A4.262,4.262,0,0,0,20.9,4.85,3.961,3.961,0,0,0,19.149,3.1a4.279,4.279,0,0,0-1.622-.368c-.836-.068-1.909-.068-3.394-.068H9.866C8.381,2.666,7.31,2.666,6.472,2.734Zm8.585,14.208L12,13.886,8.943,16.942a1.333,1.333,0,0,1-1.886-1.885L10.114,12,7.056,8.943A1.334,1.334,0,0,1,8.943,7.056L12,10.114l3.058-3.058a1.333,1.333,0,0,1,1.885,1.886L13.886,12l3.056,3.058a1.333,1.333,0,1,1-1.885,1.885Z"
                    transform="translate(0.001 0.001)" fill="#707070"/>
            </svg>
          </div>
        </div>
        <div class="header-line"></div>
      </div>
      <div class="create-container"></div>
      <form @submit.prevent="createUser">
        <div class="form-group">
          <label for="baseId">Base Name</label>
          <input type="text" id="baseId" v-model="baseId" required/>
        </div>

        <div class="form-group">
          <label for="userCount">User Count</label>
          <input type="number" id="userCount" v-model="userCount" required/>
        </div>

        <div class="form-group">
          <label for="days">Valid Days</label>
          <input type="number" id="days" v-model="daysValid" required/>
        </div>

        <div class="form-group">
          <label for="telegramId">Telegram ID</label>
          <input type="text" id="telegramId" v-model="telegramId" required/>
        </div>

        <div style="text-align: center;">
          <button type="submit" class="create-user-button">Create Users</button>
        </div>
      </form>
    </div>
  </ModalComponent>
  <ModalComponent v-if="showBatchActionModal" :isVisible="showBatchActionModal" title="Batch Action"
                  @close="showBatchActionModal = false">
    <div class="row-container2">
      <div class="upper-row">
        <div class="row-header">
          <div class="row-header-icon"
               style="width: 16px; height: 25px; background-color: #127569; border-radius: 5px;"></div>
          <div class="header-svg"
               style="display: flex; align-items: center; justify-content: space-between; width: 100%">
            <div class="row-header-title">Batch Action</div>
            <svg @click="closeModal" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 style="margin-top: -3px; margin-right: 2px">
              <path id="Combined_Shape" data-name="Combined Shape"
                    d="M9.809,24c-1.458,0-2.611,0-3.554-.077a6.771,6.771,0,0,1-2.616-.65A6.616,6.616,0,0,1,.726,20.36a6.766,6.766,0,0,1-.65-2.616C0,16.8,0,15.648,0,14.189V9.809C0,8.351,0,7.2.076,6.255a6.771,6.771,0,0,1,.65-2.616A6.622,6.622,0,0,1,3.639.726,6.771,6.771,0,0,1,6.255.076C7.2,0,8.351,0,9.809,0h4.38c1.459,0,2.611,0,3.554.077a6.766,6.766,0,0,1,2.616.65,6.616,6.616,0,0,1,2.912,2.912,6.771,6.771,0,0,1,.65,2.616C24,7.2,24,8.351,24,9.809v4.38c0,1.459,0,2.611-.077,3.554a6.766,6.766,0,0,1-.65,2.616,6.61,6.61,0,0,1-2.912,2.912,6.766,6.766,0,0,1-2.616.65C16.8,24,15.648,24,14.189,24ZM6.472,2.734A4.285,4.285,0,0,0,4.85,3.1,3.973,3.973,0,0,0,3.1,4.85a4.285,4.285,0,0,0-.368,1.622c-.068.838-.068,1.909-.068,3.394v4.267c0,1.484,0,2.557.068,3.394A4.279,4.279,0,0,0,3.1,19.149,3.967,3.967,0,0,0,4.85,20.9a4.262,4.262,0,0,0,1.622.368c.838.068,1.909.068,3.394.068h4.267c1.484,0,2.557,0,3.394-.068a4.256,4.256,0,0,0,1.622-.368A3.955,3.955,0,0,0,20.9,19.149a4.256,4.256,0,0,0,.368-1.622c.068-.836.068-1.909.068-3.394V9.866c0-1.484,0-2.556-.068-3.394A4.262,4.262,0,0,0,20.9,4.85,3.961,3.961,0,0,0,19.149,3.1a4.279,4.279,0,0,0-1.622-.368c-.836-.068-1.909-.068-3.394-.068H9.866C8.381,2.666,7.31,2.666,6.472,2.734Zm8.585,14.208L12,13.886,8.943,16.942a1.333,1.333,0,0,1-1.886-1.885L10.114,12,7.056,8.943A1.334,1.334,0,0,1,8.943,7.056L12,10.114l3.058-3.058a1.333,1.333,0,0,1,1.885,1.886L13.886,12l3.056,3.058a1.333,1.333,0,1,1-1.885,1.885Z"
                    fill="#707070"/>
            </svg>
          </div>
        </div>
        <div class="header-line"></div>
      </div>
      <div class="export-container">
        <form>
          <div class="form-group">
            <label for="startUserId">Start User</label>
            <input type="number" id="startUserId" v-model="startUserId" required/>
          </div>

          <div class="form-group">
            <label for="endUserId">End User</label>
            <input type="number" id="endUserId" v-model="endUserId" required/>
          </div>

          <div class="export-button-container" style="text-align: center;">
            <button type="button" @click="batchLogoutUsers" class="export-button">Logout</button>
            <button type="button" @click="batchChangePassword" class="export-button">Change Password</button>
          </div>
        </form>
      </div>
    </div>
  </ModalComponent>
  <ModalComponent v-if="showEditUserModal" :isVisible="showEditUserModal" title="Edit User"
                  @close="showEditUserModal = false">
    <div class="row-container2">
      <div class="upper-row">
        <div class="row-header">
          <div class="row-header-icon"
               style="width: 16px; height: 25px; background-color: #127569; border-radius: 5px;"></div>
          <div class="header-svg"
               style="display: flex; align-items: center; justify-content: space-between; width: 100%">
            <div class="row-header-title">Export User</div>
            <svg @click="closeModal" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 style="margin-top: -3px; margin-right: 2px">
              <path id="Combined_Shape" data-name="Combined Shape"
                    d="M9.809,24c-1.458,0-2.611,0-3.554-.077a6.771,6.771,0,0,1-2.616-.65A6.616,6.616,0,0,1,.726,20.36a6.766,6.766,0,0,1-.65-2.616C0,16.8,0,15.648,0,14.189V9.809C0,8.351,0,7.2.076,6.255a6.771,6.771,0,0,1,.65-2.616A6.622,6.622,0,0,1,3.639.726,6.771,6.771,0,0,1,6.255.076C7.2,0,8.351,0,9.809,0h4.38c1.459,0,2.611,0,3.554.077a6.766,6.766,0,0,1,2.616.65,6.616,6.616,0,0,1,2.912,2.912,6.771,6.771,0,0,1,.65,2.616C24,7.2,24,8.351,24,9.809v4.38c0,1.459,0,2.611-.077,3.554a6.766,6.766,0,0,1-.65,2.616,6.61,6.61,0,0,1-2.912,2.912,6.766,6.766,0,0,1-2.616.65C16.8,24,15.648,24,14.189,24ZM6.472,2.734A4.285,4.285,0,0,0,4.85,3.1,3.973,3.973,0,0,0,3.1,4.85a4.285,4.285,0,0,0-.368,1.622c-.068.838-.068,1.909-.068,3.394v4.267c0,1.484,0,2.557.068,3.394A4.279,4.279,0,0,0,3.1,19.149,3.967,3.967,0,0,0,4.85,20.9a4.262,4.262,0,0,0,1.622.368c.838.068,1.909.068,3.394.068h4.267c1.484,0,2.557,0,3.394-.068a4.256,4.256,0,0,0,1.622-.368A3.955,3.955,0,0,0,20.9,19.149a4.256,4.256,0,0,0,.368-1.622c.068-.836.068-1.909.068-3.394V9.866c0-1.484,0-2.556-.068-3.394A4.262,4.262,0,0,0,20.9,4.85,3.961,3.961,0,0,0,19.149,3.1a4.279,4.279,0,0,0-1.622-.368c-.836-.068-1.909-.068-3.394-.068H9.866C8.381,2.666,7.31,2.666,6.472,2.734Zm8.585,14.208L12,13.886,8.943,16.942a1.333,1.333,0,0,1-1.886-1.885L10.114,12,7.056,8.943A1.334,1.334,0,0,1,8.943,7.056L12,10.114l3.058-3.058a1.333,1.333,0,0,1,1.885,1.886L13.886,12l3.056,3.058a1.333,1.333,0,1,1-1.885,1.885Z"
                    transform="translate(0.001 0.001)" fill="#707070"/>
            </svg>
          </div>
        </div>
        <div class="header-line"></div>
      </div>

      <div class="edit-container">
        <form @submit.prevent="saveUserChanges">
          <div class="form-group">
            <label for="username">Username</label>
            <input type="text" id="username" v-model="selectedUser.username" readonly/>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="text" id="password" v-model="selectedUser.password"/>
          </div>
          <div class="form-group">
            <label for="telegramId">Telegram ID</label>
            <input type="text" id="telegramId" v-model="selectedUser.telegramId" required/>
          </div>
          <div class="form-group">
            <label for="planEndDate">Expire Date</label>
            <input type="date" v-model="selectedUser.planEndDate" class="custom-input" style="margin-bottom: 15px"/>
          </div>
          <div class="form-group">
            <label for="totalDevices">Total Devices</label>
            <input type="number" id="totalDevices" v-model="selectedUser.maxDevicesCount" required/>
          </div>
          <div class="form-group">
            <label for="status">Status</label>
            <select id="status" v-model="selectedUser.status" class="readonly-select" required disabled>
              <option value="1">Created</option>
              <option value="2">Active</option>
              <option value="4">Expired</option>
              <option value="3">Disable</option>
            </select>
          </div>
          <div class="modal-actions">
            <button type="submit" class="save-button">Save</button>
            <button type="button" @click="logoutUser(selectedUser)" class="logout-button">Logout</button>
          </div>
        </form>
      </div>
    </div>
  </ModalComponent>
  <Toast
      v-if="toastMessage"
      :message="toastMessage"
      :type="toastType"
      :duration="3000"
  />
</template>

<script>
import ModalComponent from './ModalComponent.vue';
import Toast from './Toast.vue';
import axios from "axios";

export default {
  components: {
    ModalComponent,
    Toast,
  },
  name: 'DashboardView',
  data() {
    return {
      toastMessage: '',
      toastType: '',
      totalUsers: 0,
      activeUsers: 0,
      expiredUsers: 0,
      blockedUsers: 0,
      baseId: '',
      userCount: '',
      daysValid: '',
      telegramId: '',
      startUserId: '',
      endUserId: '',
      showCreateUserModal: false,
      showBatchActionModal: false,
      showEditUserModal: false,
      selectedUser: null,
      currentPage: 1,
      rowsPerPage: 20,
      searchQuery: '',
      users: [],
    };
  },
  created() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.$router.push('/login');
    } else {
      this.fetchUsers();
    }
  },
  computed: {
    filteredUsers() {
      const query = this.searchQuery.toLowerCase().trim();
      return this.users
          .filter(user => {
            return (
                user.username.toLowerCase().includes(query) ||
                (user.telegramId && user.telegramId.toLowerCase().includes(query)) ||
                this.getUserStatusText(user.status).toLowerCase().includes(query)
            );
          })
          .sort((a, b) => this.sortAlphanumeric(a.username, b.username)); // اضافه کردن مرتب‌سازی ترکیبی
    },
    visiblePages() {
      const total = this.totalPages;
      const current = this.currentPage;
      const maxVisible = 7;
      const range = 3;
      let pages = [];
      if (total <= maxVisible) {
        pages = Array.from({ length: total }, (_, i) => i + 1);
      } else {
        pages.push(1);
        if (current <= range + 1) {
          for (let i = 2; i <= range + 2; i++) {
            pages.push(i);
          }
          pages.push('...');
          pages.push(total);
        } else if (current >= total - range) {
          pages.push('...');
          for (let i = total - (range + 1); i < total; i++) {
            pages.push(i);
          }
          pages.push(total);
        } else {
          pages.push('...');
          for (let i = current - 1; i <= current + 1; i++) {
            pages.push(i);
          }
          pages.push('...');
          pages.push(total);
        }
      }
      return pages;
    },
    totalPages() {
      return Math.ceil(this.filteredUsers.length / this.rowsPerPage);
    },
    filteredVisiblePages() {
      return this.visiblePages.filter(page => page !== '...');
    },
    dotPages() {
      return this.visiblePages.filter(page => page === '...');
    },
    paginatedUsers() {
      const startIndex = (this.currentPage - 1) * this.rowsPerPage;
      const endIndex = startIndex + this.rowsPerPage;
      return this.filteredUsers.slice(startIndex, endIndex);
    }
  },
  methods: {
    sortAlphanumeric(a, b) {
      const regex = /(\d+)|(\D+)/g;

      // استخراج رشته‌ها و اعداد برای هر کاربر
      const aParts = a.match(regex);
      const bParts = b.match(regex);

      for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        const aPart = aParts[i] || '';
        const bPart = bParts[i] || '';

        // اگر هر دو عدد بودند، مقایسه عددی
        if (!isNaN(aPart) && !isNaN(bPart)) {
          const aNum = parseInt(aPart, 10);
          const bNum = parseInt(bPart, 10);
          if (aNum !== bNum) {
            return aNum - bNum;
          }
        } else {
          // در غیر این صورت مقایسه رشته‌ای
          if (aPart !== bPart) {
            return aPart.localeCompare(bPart);
          }
        }
      }
      return 0; // اگر برابر بودند
    },
    sortUsersByUsername() {
      this.users.sort((a, b) => a.username.localeCompare(b.username));
    },
    fetchUsers() {
      const token = localStorage.getItem("token");
      if (!token) {
        this.showToast("Token not found. Please login again.", "error");
        return;
      }
      axios
          .get(`${process.env.VUE_APP_BASE_API_URL}/api/tenant/get-tenant-users`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            params: {activeOnly: false}
          })
          .then((response) => {
            const usersData = response.data.data || [];
            this.users = usersData;
            this.sortUsersByUsername(); // مرتب‌سازی لیست کاربران
            this.totalUsers = usersData.length;
            this.activeUsers = usersData.filter(user => user.status === 1).length;
            this.expiredUsers = usersData.filter(user => user.status === 4).length;
            this.blockedUsers = usersData.filter(user => user.status === 2).length;
          })
          .catch((error) => {
            console.error("Error fetching users:", error);
            this.showToast("Error fetching user list.", "error");
          });
    },
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/login');
    },
    createUser() {
      const token = localStorage.getItem("token");
      if (!token) {
        this.showToast("Token not found. Please login again.", "error");
        return;
      }

      const userData = {
        fromId: this.baseId,
        count: this.userCount,
        days: this.daysValid,
        telegramId: this.telegramId,
      };

      axios.post(
          `${process.env.VUE_APP_BASE_API_URL}/api/tenant/create-batch-users`,
          userData,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
      )
          .then((response) => {
            const createdUsers = response.data.users || [];
            const fileContent = createdUsers
                .map(user => `${user.username}:${user.password}`)
                .join('\n');
            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const year = today.getFullYear();
            const firstUsername = createdUsers[0]?.username || 'unknown';
            const lastUsername = createdUsers[createdUsers.length - 1]?.username || 'unknown';
            const fileName = `${createdUsers.length}User-${firstUsername}-${lastUsername}-${day}-${month}-${year}.txt`;
            const blob = new Blob([fileContent], {type: 'text/plain'});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            this.showToast('Users created and file downloaded successfully!', 'success');
            this.showCreateUserModal = false;
          })
          .catch((error) => {
            console.error("Error creating users:", error);
            this.showToast("Error creating users.", "error");
          });
    },
    calculateDaysLeft(expiryDate) {
      const expiry = new Date(expiryDate);
      const today = new Date();
      const timeDiff = expiry - today;
      const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      if (daysLeft > 0) {
        return `+${daysLeft} days`;
      } else if (daysLeft < 0) {
        return `${daysLeft} days`;
      } else {
        return "Expires today";
      }
    },
    logoutUser(user) {
      this.showToast(`User ${user.username} has been logged out.`, 'success');
    },
    getUserRowClass(status) {
      switch (status) {
        case 1:
          return 'row-yellow';
        case 2:
          return 'row-green';
        case 3:
          return 'row-gray';
        case 4:
          return 'row-red';
        case 5:
          return 'row-white';
        default:
          return '';
      }
    },
    getUserStatusText(status) {
      switch (status) {
        case 1:
          return 'Created';
        case 2:
          return 'Active';
        case 3:
          return 'Blocked';
        case 4:
          return 'Expired';
        case 5:
          return 'To hold';
        default:
          return 'Unknown';
      }
    },
    openEditModal(user) {
      const expiryDate = new Date(user.expiryDate);
      const formattedDate = expiryDate.toISOString().split('T')[0];
      this.selectedUser = {
        ...user,
        planEndDate: formattedDate,
        status: user.status,
      };
      this.showEditUserModal = true;
    },
    saveUserChanges() {
      const token = localStorage.getItem("token");
      if (!token) {
        this.showToast("Token not found. Please login again.", "error");
        return;
      }

      const userId = this.selectedUser.id;

      const updateUserPayload = {
        userId: userId,
        telegramId: this.selectedUser.telegramId,
        maxDevicesCount: this.selectedUser.maxDevicesCount,
        status: 2,
      };

      if (this.selectedUser.password) {
        updateUserPayload.password = this.selectedUser.password;
      }

      axios
          .post(`${process.env.VUE_APP_BASE_API_URL}/api/tenant/update-user`, updateUserPayload, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            this.showToast("User updated successfully!", "success");

            const updateSubscriptionPayload = {
              userId: userId,
              startDate: new Date().toISOString().split("T")[0],
              expiryDate: this.selectedUser.planEndDate,
              setToActive: true,
            };

            return axios.post(
                `${process.env.VUE_APP_BASE_API_URL}/api/tenant/create-user-subscription`,
                updateSubscriptionPayload,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
            );
          })
          .then(() => {
            this.showToast("User subscription updated successfully!", "success");
            this.fetchUsers();
            this.showEditUserModal = false;
          })
          .catch((error) => {
            console.error("Error updating user:", error);
            this.showToast("Error updating user or subscription.", "error");
          });
    },
    closeModal() {
      this.showCreateUserModal = false;
      this.showExportUserModal = false;
      this.showEditUserModal = false;
    },
    showToast(message, type) {
      this.toastMessage = message;
      this.toastType = type;
    },
    batchLogoutUsers() {
      this.showToast(`Users from ID ${this.startUserId} to ${this.endUserId} have been logged out successfully.`, 'success');
      this.showBatchActionModal = false;
    },
    batchChangePassword() {
      this.showToast(`Password for users from ID ${this.startUserId} to ${this.endUserId} have been changed successfully.`, 'success');
      this.showBatchActionModal = false;
    },
    goToPage(pageNumber) {
      if (pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  }
};
</script>

<style scoped>
* {
  font-family: 'Nunito', sans-serif;
}

.dashboard-page {
  text-align: center;
}

.navbar {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #127569;
  padding: 10px 20px;
  color: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.7);
  z-index: 999999999999 !important;
}

.navbar-left h1 {
  margin: 0;
  font-size: 23px;
  color: white;
}

.navbar-right .logout-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.logout-btn:hover {
  background-color: #c82333;
}

.dashboard-container {
  padding: 15px;
}

.row-container1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin: 50px auto 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
  padding: 10px 10px 5px 10px;
}

.row-container2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin: 10px auto 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.row-header {
  display: flex;
  justify-content: start;
  align-items: center;
}

.row-header-icon {
  margin-left: 3px;
}

.row-header-title {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
}

.header-line {
  background-color: #707070;
  border-radius: 10px;
  height: 1px;
  width: 100%;
  margin: 5px auto 10px;
}

.stats-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
}

.stat-box {
  border-radius: 10px;
  width: 95%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.stat-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.stat-icon {
  width: 17px;
  height: 17px;
  border-radius: 5px;
  margin-right: 10px;
}

.stat-title {
  font-size: 16px;
  font-weight: bold;
}

.stat-value {
  margin-top: -2px;
  font-size: 21px;
  font-weight: 600;
  color: #333;
}

.create-container {
  margin-top: 2px;
}

.export-container {
  margin-top: 2px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 10px;
}

label {
  margin-left: 10px;
  background-color: #d4d5d5;
  border-radius: 10px;
  font-weight: 600;
  width: 100px;
  font-size: 14px;
  text-align: center;
  margin-bottom: -8px;
  z-index: 1000;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.button-container {
  padding: 0 5px;
  margin: 7px auto -3px;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.open-modal-button {
  height: 40px;
  margin: 5px;
  width: 48%;
  padding: 5px;
  background-color: #127569;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.open-modal-button:hover {
  background-color: #218838;
}

.create-user-button {
  height: 40px;
  margin: 8px auto 10px;
  width: 94%;
  padding: 5px;
  background-color: #127569;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-user-button:disabled {
  background-color: #ccc;
}

.create-user-button:hover:not(:disabled) {
  background-color: #127569;
}

.export-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.export-button {
  height: 40px;
  margin: 8px auto 10px;
  width: 45%;
  padding: 5px;
  background-color: #127569;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.export-button:hover {
  background-color: #218838;
}

.export-button:disabled {
  background-color: #ccc;
}

.rows-per-page-select {
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: bold;
}

.search-container {
  padding: 0 5px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.search-input {
  width: 100%;
  padding: 8px 8px 8px 45px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

.search-icon {
  margin-top: -5px;
  position: absolute;
  left: 17px;
  color: #888;
  pointer-events: none;
}

select {
  padding: 8px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: bold;
}

.user-table {
  width: 100%;
  max-width: 480px;
  border-collapse: collapse;
  margin: 0;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #cacaca;
  overflow: hidden;
  border: 1px solid #ddd;
}

.user-table th, .user-table td {
  font-size: 15px;
  height: 35px;
  border: 1px solid #ddd;
  padding: 2px 3px;
  text-align: center;
}

.user-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.row-green {
  background-color: #d1f4e1;
}

.row-red {
  background-color: #ffe0e0;
}

.row-black {
  background-color: #d5d5d5;
}

.row-yellow {
  background-color: #fff9bc;
}

.column-user-pass {
  width: 20%;
}

.column-expiry {
  width: 15%;
}

.column-status {
  width: 10%;
}

.column-action {
  width: 10%;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

.action-button {
  background-color: #127569;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #218838;
}

.modal-actions {
  padding: 15px 10px 10px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.save-button, .logout-button {
  padding: 9px 18px;
  width: 50%;
  border: none;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.save-button {
  background-color: #127569;
  color: white;
}

.logout-button {
  background-color: #dc3545;
  color: white;
}

.save-button:hover {
  background-color: #218838;
}

.logout-button:hover {
  background-color: #c82333;
}

.rows-per-page-select {
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: bold;
  height: 28px;
}

.readonly-select:disabled {
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: default;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.pagination-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: -5px;

}

.pagination-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: -5px;
}

.nm-button {
  background-color: #fff;
  color: #127569;
  border: none;
  margin: 0 2px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.bp-button {
  background-color: #fff;
  color: #127569;
  border: none;
  margin: 0 2px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination .active-page {
  background-color: #127569;
  color: #fff;
}

.dots {
  pointer-events: none;
  cursor: default;
  color: #ccc;
  font-weight: normal;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
  margin: 0;
}
</style>
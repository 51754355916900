import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const app = createApp(App);

app.use(router);
app.use(Toast, {
    // پیکربندی دلخواه برای toast
    timeout: 3000, // زمان نمایش toast
    position: "top-right", // محل نمایش
});

app.mount('#app');

<!-- Toast.vue -->
<template>
  <div
      v-if="visible"
      :class="['toast', type]"
      @click="closeToast"
      @mouseover="pauseTimer"
      @mouseleave="resumeTimer"
  >
    <span class="toast-message">{{ message }}</span>
    <button class="close-btn" @click="closeToast">&times;</button>

    <div class="progress-bar" :style="{ width: progressBarWidth + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: 'ShowToast',
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'success', // 'success' یا 'error'
    },
    duration: {
      type: Number,
      default: 3000, // مدت زمان نمایش توست
    },
  },
  data() {
    return {
      visible: true,
      progressBarWidth: 100,
      isPaused: false,
      toastTimeout: null,
    };
  },
  mounted() {
    this.startProgress();
    this.toastTimeout = setTimeout(() => {
      this.hideToast();
    }, this.duration);
  },
  methods: {
    startProgress() {
      let progressInterval = setInterval(() => {
        if (this.progressBarWidth > 0 && !this.isPaused) {
          this.progressBarWidth -= 1;
        } else if (this.progressBarWidth <= 0) {
          clearInterval(progressInterval);
        }
      }, this.duration / 100);
    },
    pauseTimer() {
      this.isPaused = true;
      clearTimeout(this.toastTimeout);
    },
    resumeTimer() {
      this.isPaused = false;
      this.toastTimeout = setTimeout(() => {
        this.hideToast();
      }, this.progressBarWidth * (this.duration / 100));
    },
    hideToast() {
      this.visible = false;
    },
    closeToast() {
      this.hideToast();
    },
  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 6px 10px 10px 5px;
  border-radius: 5px;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999999999999999999999999999;
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  height: 43px;
  animation: fadeout 3s ease-in-out forwards;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.toast.success {
  background-color: #28a745;
}

.toast.error {
  background-color: #dc3545;
}

.toast-message {
  margin-right: 10px;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: width 0.3s linear;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 0;
  width: 30px;
  padding: 0;
}
</style>
